import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MenuIcon from '@mui/icons-material/Menu'
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
  useTheme,
} from '@mui/material'
import { Link } from 'gatsby'
import React, { useState } from 'react'
import DarkModeSwitch from '../DarkModeSwitch'
import { useDarkModeContext } from '../Layout'
import { NAV_ITEMS } from './NavbarMenu'

const MobileListMenuItem = ({ sxObj, path, name, onClick, externalLink }) => {
  const { isDarkMode } = useDarkModeContext()

  const { palette } = useTheme()
  return (
    <ListItem onClick={onClick} key={name} disablePadding>
      <ListItemButton
        partiallyActive
        activeStyle={{
          background: isDarkMode ? palette.primary.light : palette.grey['400'],
        }}
        component={Link}
        to={path}
        target={externalLink ? '_blank' : ''}
      >
        <ListItemText sx={sxObj} primary={name} />
      </ListItemButton>
    </ListItem>
  )
}

const NavbarMobileMenu = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <IconButton onClick={() => setOpen(true)} color="inherit" aria-label="menu">
        <MenuIcon fontSize="large" />
      </IconButton>
      <SwipeableDrawer anchor="right" open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
        <Box sx={{ width: 250 }}>
          <List>
            {NAV_ITEMS.map(({ name, path, innerItems }) =>
              innerItems ? (
                <React.Fragment key={name}>
                  <ListItem disablePadding>
                    <ListItemButton
                      disableRipple
                      sx={{
                        '&:hover': {
                          backgroundColor: 'inherit',
                          cursor: 'default',
                          justifyContent: 'start',
                        },
                      }}
                    >
                      <ListItemText sx={{ flexGrow: 0.1 }} primary={name} />
                      <ExpandMoreIcon fontSize="medium" />
                    </ListItemButton>
                  </ListItem>
                  {innerItems.map(({ name: innerName, path: innerPath }) => (
                    <MobileListMenuItem
                      key={innerName}
                      onClick={() => setOpen(false)}
                      name={innerName}
                      path={innerPath}
                      sxObj={{ ml: 3 }}
                    />
                  ))}
                </React.Fragment>
              ) : (
                <MobileListMenuItem key={name} onClick={() => setOpen(false)} name={name} path={path} />
              )
            )}
            <Divider />
            <MobileListMenuItem onClick={() => setOpen(false)} name="Współpraca" path="/kontakt" />
            <MobileListMenuItem
              onClick={() => setOpen(false)}
              name="Wspieraj nas"
              path="https://suppi.pl/kalkulatorpro"
              externalLink
            />
            <ListItem>
              <DarkModeSwitch />
            </ListItem>
          </List>
        </Box>
      </SwipeableDrawer>
    </>
  )
}

export default NavbarMobileMenu
