import React from 'react'
import { Link, Paper, Typography } from '@mui/material'
import { Link as Glink } from 'gatsby'
import CollabIcon from '../../images/collab-icon.svg'
import { useDarkModeContext } from '../Layout/Layout'

const FILTER_PRIMARY =
  'invert(9%) sepia(88%) saturate(3317%) hue-rotate(206deg) brightness(92%) contrast(102%)'
const FILTER_SUCCESS = 'invert(74%) sepia(9%) saturate(2014%) hue-rotate(73deg) brightness(86%) contrast(94%)'

export const CollabNav = () => {
  const { isDarkMode } = useDarkModeContext()

  return (
    <Paper
      sx={{
        mt: {
          xs: 2,
          md: 4,
        },
        py: 2,
        px: 2,
        textAlign: 'center',
      }}
    >
      <img
        style={{
          filter: FILTER_SUCCESS,
        }}
        width={40}
        src={CollabIcon}
        alt="Współpraca biznesowa"
      />
      <Typography sx={{ mb: 0.5, fontWeight: 600 }}>Współpraca</Typography>
      <Typography sx={{ mb: 1 }} variant="body1">
        Chcesz zamieścić u nas swoją reklamę?
      </Typography>

      <Link color="success.main" component={Glink} to="/kontakt">
        Napisz do nas
      </Link>
    </Paper>
  )
}
