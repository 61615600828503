import { useTheme } from '@emotion/react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Divider, MenuItem, Popover } from '@mui/material'
import { Link } from 'gatsby'
import React, { useRef, useState } from 'react'
import DarkModeSwitch from '../DarkModeSwitch'

export const NAV_ITEMS = [
  { name: 'Ogrzewanie', path: '/ogrzewanie/' },
  { name: 'Gaz', path: '/gaz/' },
  { name: 'Wod-Kan', path: '/wod-kan/' },
  { name: 'Wentylacja', path: '/wentylacja/' },
  {
    name: 'Inne kalkulatory',
    innerItems: [
      { name: 'Przelicznik jednostek', path: '/konwerter-jednostek/' },
      { name: 'Pojemność rur', path: '/pojemnosc-rur/' },
      { name: 'Sprężone powietrze', path: '/sprezone-powietrze/' },
      { name: 'Natężenie przepływu deszczówki', path: '/natezenie-przeplywu-deszczowki/' },
    ],
  },
  { name: 'Wiedza', path: '/wiedza/' },
]
const ACTIVE_COLOR = '#7ebcff'

const NavbarMenuItem = ({ path, name, sxObject }) => (
  <MenuItem
    key={path}
    activeStyle={{ color: ACTIVE_COLOR }}
    partiallyActive
    component={Link}
    to={path}
    sx={{ fontSize: '1.05rem', py: 1, ...sxObject }}
  >
    {name}
  </MenuItem>
)

const isCurrentPath = (pathsArray) => pathsArray.some((path) => location.pathname.includes(path))

const NavbarMenu = () => {
  const [openedPopover, setOpenedPopover] = useState(false)
  const popoverAnchor = useRef(null)
  const { palette } = useTheme()

  if (popoverAnchor.current) {
    popoverAnchor.current.classList.remove('Mui-focusVisible')
  }

  const popoverEnter = () => {
    setOpenedPopover(true)
  }

  const popoverLeave = () => {
    setOpenedPopover(false)
  }
  return (
    <Box sx={{ display: 'flex', height: '64px' }}>
      {NAV_ITEMS.map(({ name, path, innerItems }) =>
        innerItems ? (
          <MenuItem
            onMouseEnter={popoverEnter}
            onMouseLeave={popoverLeave}
            disableRipple
            key={name}
            sx={{
              fontSize: '1.05rem',
              py: 1,
              color: isCurrentPath(innerItems.map((item) => item.path)) ? ACTIVE_COLOR : '',
            }}
            ref={popoverAnchor}
          >
            {name}
            &nbsp;
            <ExpandMoreIcon fontSize="medium" />
            <Popover
              disableScrollLock
              id="mouse-over-popover"
              open={openedPopover}
              anchorEl={popoverAnchor.current}
              sx={{ pointerEvents: 'none' }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                onMouseEnter: popoverEnter,
                onMouseLeave: popoverLeave,
                sx: { pointerEvents: 'auto', background: palette.primary.main, borderRadius: 0 },
              }}
            >
              {innerItems.map(({ name: innerName, path: innerPath }) => (
                <NavbarMenuItem
                  sxObject={{ px: 3, py: 2, color: 'white' }}
                  key={innerName + innerPath}
                  name={innerName}
                  path={innerPath}
                />
              ))}
            </Popover>
          </MenuItem>
        ) : (
          <NavbarMenuItem key={name + path} name={name} path={path} />
        )
      )}
      <Divider
        sx={{ mx: { sm: 2, lg: 3 }, borderColor: 'hsla(255, 100%, 100%, 0.2)' }}
        orientation="vertical"
        flexItem
      />
      <DarkModeSwitch />
    </Box>
  )
}

export default NavbarMenu
